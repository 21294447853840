import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  Box,
  TextField,
  MenuItem,
  Card,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import Switch from "@mui/material/Switch";
import { useStyles } from "../../../../../Styles/style";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Facebook } from "react-feather";
import { styled } from "@mui/system";
import { InfoOutlined } from "@mui/icons-material";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const AttributesModal = ({
  item,
  result,
  filteredItemsArr,
  handleChangeAttributeValue,
  index,
  setSelectedItem,
  handleChangeURL,
  handleChangeEnum,
  handleChangeSwitch,
  removeAttribute,
  setEnumRequired,
  setUrlRequired,
  setUrlValidation,
  handleRepeatedEnumSwitch,
  urlPlatforms,
  setUrlPlatforms,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  useEffect(() => {
    setSelectedItem(item);
  }, []);

  useEffect(() => {
    if (item !== undefined) {
      if (item?.uriValues !== undefined) {
        validateRegex(item?.uriValues[0]?.uri, item);
      }
    }
  }, []);

  var protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;

  var localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/;
  var nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;
  function validURLOther(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }

  function validURL(string) {
    if (typeof string !== "string") {
      return false;
    }

    var match = string.match(protocolAndDomainRE);
    if (!match) {
      return false;
    }

    var everythingAfterProtocol = match[1];
    if (!everythingAfterProtocol) {
      return false;
    }

    if (
      localhostDomainRE.test(everythingAfterProtocol) ||
      nonLocalhostDomainRE.test(everythingAfterProtocol)
    ) {
      return true;
    }

    return false;
  }

  function handleDisplayName(name) {
    let text = "";
    if (name !== "" && name !== undefined) {
      const splittedText = name.split("/");

      const finalText = splittedText[1]?.replaceAll("_", " ");
      text =
        finalText[0]?.toUpperCase() + finalText?.substring(1)?.toLowerCase();
    }

    return text;
  }

  const validateRegex = (value, attribute) => {
    if (value.length > 0) {
      setUrlRequired(false);
      const filtered = urlPlatforms.find(
        (platform) => platform.name === attribute?.name
      ); 

      setUrlPlatforms((prev) =>
        prev.map((platform) =>
          platform.name === attribute?.name
            ? { ...platform, error: !filtered?.regex?.test(value) }
            : platform
        )
      );
    } else {
      setUrlRequired(true);
    }
  };

  return (
    <Grid container spacing={2} sx={{ marginTop: "12px" }}>
      <Grid
        item
        xs={7}
        sm={7}
        md={item?.valueType === "BOOL" ? 10 : 6}
        lg={item?.valueType === "BOOL" ? 10 : 6}
      >
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={filteredItemsArr?.length > 0 ? filteredItemsArr : []}
          value={item}
          onChange={(e, value) => {
            handleChangeAttributeValue(value, index, item);
          }}
          getOptionLabel={(option) =>
            option?.displayName
              ? option?.displayName
              : handleDisplayName(option?.internalName)
          }
          fullWidth
          renderInput={(params) => (
            <TextField placeholder={t("select_attr")} {...params} />
          )}
        />
      </Grid>
      {item?.valueType !== "BOOL" && (
        <Grid item xs={4} sm={4} md={4} lg={4}>
          {result?.valueType === "URL" ? (
            <TextField
              fullWidth
              InputProps={{
                inputProps: {
                  style: { marginLeft: "4px" },
                },
              }}
              name="locationName"
              placeholder={t("Enter_URl")}
              required
              value={item?.uriValues?.length > 0 ? item?.uriValues[0]?.uri : ""}
              onChange={(e) => {
                handleChangeURL(e, item, index);
                validateRegex(e.target.value, item);
              }}
              error={
                urlPlatforms.find((platform) => platform.name === item?.name)
                  ?.error
              }
              helperText={
                (item?.uriValues[0]?.uri?.length === 0 && t("URL_is_Required")) ||
                (urlPlatforms.find((platform) => platform.name === item?.name)
                  ?.error && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <BootstrapTooltip
                      title={
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "2px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "10px",
                            }}
                          >
                            {t("Valid_URL_formats")}
                          </Typography>
                          {urlPlatforms
                            .find((platform) => platform.name === item?.name)
                            ?.helperText?.map((text, i) => (
                              <Typography
                                sx={{
                                  fontSize: "10px",
                                }}
                                key={i}
                              >
                                {text}
                              </Typography>
                            ))}
                        </Box>
                      }
                    >
                      <span>
                        <InfoOutlined sx={{ fontSize: 14 }} />
                      </span>
                    </BootstrapTooltip>
                    <Typography sx={{ fontSize: "12px", marginTop: "-4px" }}>
                      {t("Invalid_Url")}
                    </Typography>
                  </Box>
                ))
              }
            />
          ) : result?.valueType === "ENUM" ? (
            <>
              <TextField
                style={{ width: "100%" }}
                onChange={(e, value) => {
                  handleChangeEnum(e.target.value, item, index);
                  if (value === null) {
                    setEnumRequired(true);
                  } else {
                    setEnumRequired(false);
                  }
                }}
                select // tell TextField to render select
                label={`${t("Select")} ${item?.displayName}`}
                error={item?.enumRequired}
                helperText={item?.enumRequired && t("Field_is_Required")}
                value={
                  item?.values?.length > 0
                    ? item?.values[0] === false || item?.values[0] === true
                      ? ""
                      : item?.values[0]
                    : ""
                }
              >
                {result?.valueMetadata?.length > 0 &&
                  result?.valueMetadata?.map((item, index) => (
                    <MenuItem key={index} value={item?.value}>
                      {item?.displayName}
                    </MenuItem>
                  ))}
              </TextField>
            </>
          ) : (
            result?.valueType === "REPEATED_ENUM" && (
              <>
                <Card
                  variant="outlined"
                  sx={{ borderColor: "#bfbfbf", borderRadius: "4px" }}
                >
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{ /*  border: "#1b2430" */ borderRadius: "8px" }}
                    >
                      <Typography
                      // sx={{ padding: "8px" }}
                      //  className={classes.accordianText}
                      >
                        {item?.displayName}
                      </Typography>
                      <Divider />
                    </AccordionSummary>

                    <AccordionDetails>
                      {result?.valueMetadata?.length > 0 &&
                        result?.valueMetadata?.map((itemOne, ind) => (
                          <Grid container key={ind}>
                            <Grid
                              item
                              xs={8}
                              sm={8}
                              lg={8}
                              md={8}
                              sx={{ paddingTop: "8px" }}
                            >
                              <Typography
                                className={
                                  item?.repeatedEnumValue?.setValues?.includes(
                                    itemOne
                                  ) === true ||
                                  item?.repeatedEnumValue?.unsetValues?.includes(
                                    itemOne
                                  ) === true
                                    ? classes.selectedAttributes
                                    : classes.unSelectedAttributes
                                }
                              >
                                {itemOne?.displayName}
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} lg={4} md={4}>
                              <Box sx={{ display: "flex" }}>
                                <Switch
                                  className={
                                    item?.repeatedEnumValue?.setValues?.includes(
                                      itemOne
                                    ) === true
                                      ? classes.toggleUnChecked
                                      : classes.toggleChecked
                                  }
                                  checked={item?.repeatedEnumValue?.setValues?.includes(
                                    itemOne
                                  )}
                                  onChange={(e) =>
                                    handleRepeatedEnumSwitch(
                                      e,
                                      itemOne,
                                      index,
                                      ind
                                    )
                                  }
                                />
                                <Typography sx={{ paddingTop: "8px" }}>
                                  {" "}
                                  {item?.repeatedEnumValue?.setValues?.includes(
                                    itemOne
                                  ) === true
                                    ? t("Yes")
                                    : t("No")}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        ))}
                    </AccordionDetails>
                  </Accordion>
                </Card>
              </>
            )
          )}
        </Grid>
      )}
      <Grid xs={1.5} sm={1.5} md={1} lg={1} sx={{ display: "flex" }}>
        {item?.valueType === "BOOL" && (
          <Box sx={{ display: "flex", paddingTop: "10px" }}>
            <Switch
              className={
                item?.values?.length > 0 && item?.values[0] === true
                  ? classes.toggleUnChecked
                  : classes.toggleChecked
              }
              checked={
                item?.values?.length > 0 && item?.values[0] === true
                  ? true
                  : false
              }
              onChange={(e) => handleChangeSwitch(e, index)}
            />
            <Typography sx={{ paddingTop: "8px" }}>
              {" "}
              {item?.values?.length > 0 && item?.values[0] === true
                ? t("Yes")
                : t("No")}
            </Typography>
          </Box>
        )}
      </Grid>
      <Grid item xs={1} sm={1} md={1} lg={1} sx={{ textAlign: "end" }}>
        <IconButton onClick={() => removeAttribute(index)}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default AttributesModal;
