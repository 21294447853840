export const useScrollToElement = (refs) => {
  const scrollToElement = () => {
    for (const ref of refs) {
      if (ref.current && !ref.current.value) {
        ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
        ref.current.focus();
        break;
      }
    }
  };

  return scrollToElement;
};
