import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Paper,
  Autocomplete,
  TextField,
  Table,
  TableBody,
  TableContainer,
  Button,
  Box,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import GoogleImg from "../../../../../assets/Icons/googleImg.svg";

import CustomizedTableCell from "../../../../../components/CustomComponents/table/tableCell";
import CustomizedTableHead from "../../../../../components/CustomComponents/table/tableHead";
import CustomizedTableRow from "../../../../../components/CustomComponents/table/tableRow";
import TableSkeletonLoader from "../TableSkeletonLoader";
import NoDataFound from "./NoDataFound/NoDataFoundOnlineReputation";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import { useStyles } from "../../Style/style";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-arrow`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const PlatformOverview = ({
  platformOverviewData,
  platformOverviewLoading,
  platformData,
  handleOpenReviewModel,
  setSelectedLocationId,
  setThirdPartySourceId,
  tooltipContent,
  downloadFormat,
  setDownloadFormat,
  viewPermission,
  pdfChecked,
  pdfDownloadChecked,
  openDownloadPdfModal,
  openReportEmailModal,
  setFeaturesObj,
  featuresObj,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [dataCount, setDataCount] = useState(5);
  const [downloadValue, setDownloadValue] = useState(null);

  useEffect(() => {
    if (downloadFormat === "") {
      setDownloadValue(null);
    }
  }, [downloadFormat]);

  useEffect(() => {
    if (
      platformOverviewData !== null &&
      platformOverviewData?.overview?.length > 0
    ) {
      setDataCount(platformOverviewData?.overview?.length);
    } else {
      setDataCount(5);
    }
  }, [
    pdfChecked,
    pdfDownloadChecked,
    openDownloadPdfModal,
    openReportEmailModal,
  ]);

  function handleArrows(value, lastValue) {
    let precision = "positive";
    const difference = value - lastValue;
    if (difference === 0) {
      precision = "neutral";
    } else if (difference > 0) {
      precision = "positive";
    } else {
      precision = "negative";
    }
    return precision;
  }

  const loadMoreData = () => {
    if (platformOverviewData !== null && platformOverviewData?.length > 0) {
      setDataCount(platformOverviewData?.length);
    }
  };
  const loadLessData = () => {
    setDataCount(5);
  };
  const baseURL = process.env.REACT_APP_BASE_URL;

  const headCells = [
    { id: "platform", alignment: "left", label: t("platforms") },
    { id: "ratings", alignment: "left", label: t("ratings") },
    { id: "satisfaction", alignment: "left", label: t("satisfaction") },
    { id: "reviewsPost", alignment: "left", label: t("reviewsAndPosts") },
  ];
  const downloadOptions = [
    { id: 1, value: "download", title: t("downlaod_pdf") },
    { id: 2, value: "email", title: t("email_report") },
  ];
  const handlePlatformDownloadChange = (event, newValue) => {
    setDownloadFormat(newValue?.value);
    setDownloadValue(newValue);

    let tempFeatureObj = featuresObj;
    Object.keys(tempFeatureObj)?.map((itemTwo) => {
      if (itemTwo === "platformOverview") {
        tempFeatureObj[itemTwo] = true;
      } else {
        tempFeatureObj[itemTwo] = false;
      }
    });
    setFeaturesObj(tempFeatureObj);
  };
  return (
    <>
      <Grid container className={classes.mainContainer}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container mb={3}>
            <Grid item xs={9.7} sm={9.7} md={9.7} lg={9.7}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography className={classes.subHeadRatingReviewDetail}>
                  {t("platform_overview")}
                </Typography>
                <BootstrapTooltip title={t("Platform_Overview_Tooltip")}>
                  <Typography
                    sx={{
                      marginLeft: "5px",
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <InfoOutlinedIcon />
                  </Typography>
                </BootstrapTooltip>
              </Box>
            </Grid>
            {viewPermission && (
              <Grid item xs={2.3} sm={2.3} md={2.3} lg={2.3}>
                <Autocomplete
                  id="tags-outlined"
                  options={downloadOptions}
                  value={downloadValue}
                  getOptionLabel={(option) => option.title}
                  filterSelectedOptions
                  onChange={handlePlatformDownloadChange}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t("actionss")} />
                  )}
                />
              </Grid>
            )}
          </Grid>
          <Grid container className={classes.tableContainer}>
            <TableContainer component={Paper}>
              <Table
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                {platformOverviewLoading ? (
                  <TableSkeletonLoader />
                ) : (
                  <>
                    <CustomizedTableHead>
                      <CustomizedTableRow>
                        {headCells.map((headCell) => (
                          <CustomizedTableCell
                            sx={{
                              fontWeight: 700,
                              fontSize: "14px",
                              paddingRight: "26px",
                            }}
                            key={headCell.id}
                            align={headCell.alignment}
                          >
                            {headCell.label}
                          </CustomizedTableCell>
                        ))}

                        <CustomizedTableCell
                          sx={{
                            fontWeight: 700,
                            fontSize: "14px",
                            paddingRight: "26px",
                          }}
                          key={"reviewsReplied"}
                          align={"left"}
                        >
                          {t("reviewsReplied")}
                        </CustomizedTableCell>
                      </CustomizedTableRow>
                    </CustomizedTableHead>

                    <TableBody>
                      {platformOverviewData?.length > 0 &&
                        platformOverviewData
                          ?.slice(0, dataCount)
                          .map((item, index) => {
                            const thirdpartySource =
                              platformData?.thirdPartySource?.find(
                                (reviewSource) =>
                                  reviewSource?.id ===
                                  item?.thirdPartyReviewSourcesId
                              );
                            return (
                              <CustomizedTableRow
                                sx={{ fontWeight: "700px" }}
                                tabIndex={-1}
                                key={index}
                              >
                                <CustomizedTableCell align="left">
                                  <span>
                                    <img
                                      alt=""
                                      src={
                                        thirdpartySource !== undefined &&
                                        thirdpartySource !== null
                                          ? `${baseURL}/${thirdpartySource?.image}`
                                          : GoogleImg
                                      }
                                      style={{ width: 20, height: 20 }}
                                      className={classes.imgAlign}
                                    />
                                  </span>
                                  <span className={classes.textAligns}>
                                    {thirdpartySource !== undefined &&
                                    thirdpartySource !== null
                                      ? thirdpartySource?.name
                                          .charAt(0)
                                          .toUpperCase() +
                                        thirdpartySource?.name.slice(1)
                                      : "Google"}
                                  </span>
                                </CustomizedTableCell>

                                <CustomizedTableCell
                                  sx={{ cursor: "pointer" }}
                                  align="left"
                                  onClick={() => {
                                    if (
                                      item?.totalRating !== undefined &&
                                      item?.totalRating !== null &&
                                      item?.totalRating > 0
                                    ) {
                                      setThirdPartySourceId(
                                        item?.thirdPartyReviewSourcesId
                                      );
                                      setSelectedLocationId(item?.locationId);
                                      handleOpenReviewModel(
                                        "new",
                                        true,
                                        item?.thirdPartyReviewSourcesId,
                                        "platform"
                                      );
                                    }
                                  }}
                                >
                                  <BootstrapTooltip
                                    title={
                                      <h1 style={{ fontSize: "12px" }}>
                                        {`${tooltipContent}`}
                                      </h1>
                                    }
                                  >
                                    <span>
                                      <span
                                        style={{
                                          color:
                                            item?.totalRating >= 4.2
                                              ? "#13CF8F"
                                              : item?.totalRating >= 4.0 &&
                                                item?.totalRating < 4.2
                                              ? "#F8A92B"
                                              : "#FF1616",
                                        }}
                                      >
                                        {item?.totalRating ? (
                                          item?.totalRating.toFixed(2)
                                        ) : (
                                          <span
                                            style={{
                                              color: "#FF1616",
                                            }}
                                          >
                                            {"0.00"}
                                          </span>
                                        )}
                                      </span>

                                      {handleArrows(
                                        item?.totalRating !== null &&
                                          !isNaN(item?.totalRating) &&
                                          isFinite(item?.totalRating)
                                          ? item?.totalRating
                                          : 0,
                                        item?.totalLastMonthRating !== null &&
                                          !isNaN(item?.totalLastMonthRating) &&
                                          isFinite(item?.totalLastMonthRating)
                                          ? item?.totalLastMonthRating
                                          : 0
                                      ) === "positive" ? (
                                        <ArrowUpwardIcon
                                          className={classes.greenIconClass}
                                        />
                                      ) : handleArrows(
                                          item?.totalRating !== null &&
                                            !isNaN(item?.totalRating) &&
                                            isFinite(item?.totalRating)
                                            ? item?.totalRating
                                            : 0,
                                          item?.totalLastMonthRating !== null &&
                                            !isNaN(
                                              item?.totalLastMonthRating
                                            ) &&
                                            isFinite(item?.totalLastMonthRating)
                                            ? item?.totalLastMonthRating
                                            : 0
                                        ) === "negative" ? (
                                        <ArrowDownwardIcon
                                          className={classes.redIconClass}
                                        />
                                      ) : (
                                        <ArrowForwardIcon
                                          className={classes.orangeIconClass}
                                        />
                                      )}
                                    </span>
                                  </BootstrapTooltip>
                                </CustomizedTableCell>

                                <CustomizedTableCell
                                  align="left"
                                  onClick={() => {
                                    if (
                                      item?.totalSatisfactoryLevel !==
                                        undefined &&
                                      item?.totalSatisfactoryLevel !== null &&
                                      item?.totalSatisfactoryLevel > 0
                                    ) {
                                      setThirdPartySourceId(
                                        item?.thirdPartyReviewSourcesId
                                      );
                                      setSelectedLocationId(item?.locationId);

                                      handleOpenReviewModel(
                                        "new",
                                        true,
                                        item?.thirdPartyReviewSourcesId,
                                        "platform"
                                      );
                                    }
                                  }}
                                  sx={{
                                    color:
                                      handleArrows(
                                        item?.totalSatisfactoryLevel !== null &&
                                          !isNaN(
                                            item?.totalSatisfactoryLevel
                                          ) &&
                                          isFinite(item?.totalSatisfactoryLevel)
                                          ? item?.totalSatisfactoryLevel
                                          : 0,
                                        item?.totalLastMonthSatisfactoryLevel !==
                                          null &&
                                          !isNaN(
                                            item?.totalLastMonthSatisfactoryLevel
                                          ) &&
                                          isFinite(
                                            item?.totalLastMonthSatisfactoryLevel
                                          )
                                          ? item?.totalLastMonthSatisfactoryLevel
                                          : 0
                                      ) === "positive"
                                        ? "#0FA672"
                                        : handleArrows(
                                            item?.totalSatisfactoryLevel !==
                                              null &&
                                              !isNaN(
                                                item?.totalSatisfactoryLevel
                                              ) &&
                                              isFinite(
                                                item?.totalSatisfactoryLevel
                                              )
                                              ? item?.totalSatisfactoryLevel
                                              : 0,
                                            item?.totalLastMonthSatisfactoryLevel !==
                                              null &&
                                              !isNaN(
                                                item?.totalLastMonthSatisfactoryLevel
                                              ) &&
                                              isFinite(
                                                item?.totalLastMonthSatisfactoryLevel
                                              )
                                              ? item?.totalLastMonthSatisfactoryLevel
                                              : 0
                                          ) === "negative"
                                        ? "#FF1616"
                                        : "#F8A92B",
                                    cursor: "pointer",
                                  }}
                                >
                                  <BootstrapTooltip
                                    title={
                                      <h1 style={{ fontSize: "12px" }}>
                                        {`${tooltipContent}`}
                                      </h1>
                                    }
                                  >
                                    <span>
                                      {item?.totalSatisfactoryLevel !== null &&
                                      !isNaN(item?.totalSatisfactoryLevel) &&
                                      isFinite(item?.totalSatisfactoryLevel)
                                        ? (item?.totalSatisfactoryLevel).toFixed(
                                            2
                                          ) + "%"
                                        : "0.00%"}

                                      {handleArrows(
                                        item?.totalSatisfactoryLevel !== null &&
                                          !isNaN(
                                            item?.totalSatisfactoryLevel
                                          ) &&
                                          isFinite(item?.totalSatisfactoryLevel)
                                          ? item?.totalSatisfactoryLevel
                                          : 0,
                                        item?.totalLastMonthSatisfactoryLevel !==
                                          null &&
                                          !isNaN(
                                            item?.totalLastMonthSatisfactoryLevel
                                          ) &&
                                          isFinite(
                                            item?.totalLastMonthSatisfactoryLevel
                                          )
                                          ? item?.totalLastMonthSatisfactoryLevel
                                          : 0
                                      ) === "positive" ? (
                                        <ArrowUpwardIcon
                                          className={classes.greenIconClass}
                                        />
                                      ) : handleArrows(
                                          item?.totalSatisfactoryLevel !==
                                            null &&
                                            !isNaN(
                                              item?.totalSatisfactoryLevel
                                            ) &&
                                            isFinite(
                                              item?.totalSatisfactoryLevel
                                            )
                                            ? item?.totalSatisfactoryLevel
                                            : 0,
                                          item?.totalLastMonthSatisfactoryLevel !==
                                            null &&
                                            !isNaN(
                                              item?.totalLastMonthSatisfactoryLevel
                                            ) &&
                                            isFinite(
                                              item?.totalLastMonthSatisfactoryLevel
                                            )
                                            ? item?.totalLastMonthSatisfactoryLevel
                                            : 0
                                        ) === "negative" ? (
                                        <ArrowDownwardIcon
                                          className={classes.redIconClass}
                                        />
                                      ) : (
                                        <ArrowForwardIcon
                                          className={classes.orangeIconClass}
                                        />
                                      )}
                                    </span>
                                  </BootstrapTooltip>
                                </CustomizedTableCell>

                                <CustomizedTableCell
                                  align="left"
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (
                                      item?.totalReviewCount !== undefined &&
                                      item?.totalReviewCount !== null &&
                                      item?.totalReviewCount > 0
                                    ) {
                                      setThirdPartySourceId(
                                        item?.thirdPartyReviewSourcesId
                                      );
                                      setSelectedLocationId(item?.locationId);

                                      handleOpenReviewModel(
                                        "new",
                                        true,
                                        item?.thirdPartyReviewSourcesId,
                                        "platform"
                                      );
                                    }
                                  }}
                                >
                                  <BootstrapTooltip
                                    title={
                                      <h1 style={{ fontSize: "12px" }}>
                                        {`${tooltipContent}`}
                                      </h1>
                                    }
                                  >
                                    <span>
                                      {item?.totalReviewCount}

                                      {handleArrows(
                                        item?.totalReviewCount !== null &&
                                          !isNaN(item?.totalReviewCount) &&
                                          isFinite(item?.totalReviewCount)
                                          ? item?.totalReviewCount
                                          : 0,
                                        item?.totalLastMonthReviewCount !==
                                          null &&
                                          !isNaN(
                                            item?.totalLastMonthReviewCount
                                          ) &&
                                          isFinite(
                                            item?.totalLastMonthReviewCount
                                          )
                                          ? item?.totalLastMonthReviewCount
                                          : 0
                                      ) === "positive" ? (
                                        <ArrowUpwardIcon
                                          className={classes.greenIconClass}
                                        />
                                      ) : handleArrows(
                                          item?.totalReviewCount !== null &&
                                            !isNaN(item?.totalReviewCount) &&
                                            isFinite(item?.totalReviewCount)
                                            ? item?.totalReviewCount
                                            : 0,
                                          item?.totalLastMonthReviewCount !==
                                            null &&
                                            !isNaN(
                                              item?.totalLastMonthReviewCount
                                            ) &&
                                            isFinite(
                                              item?.totalLastMonthReviewCount
                                            )
                                            ? item?.totalLastMonthReviewCount
                                            : 0
                                        ) === "negative" ? (
                                        <ArrowDownwardIcon
                                          className={classes.redIconClass}
                                        />
                                      ) : (
                                        <ArrowForwardIcon
                                          className={classes.orangeIconClass}
                                        />
                                      )}
                                    </span>
                                  </BootstrapTooltip>
                                </CustomizedTableCell>

                                <CustomizedTableCell align="left">
                                  <span>
                                    {item?.totalReviewReplyCount &&
                                    item?.totalReviewCount
                                      ? (
                                          (item?.totalReviewReplyCount /
                                            item?.totalReviewCount) *
                                          100
                                        ).toFixed(2)
                                      : "0.00"}
                                    %
                                  </span>
                                </CustomizedTableCell>
                              </CustomizedTableRow>
                            );
                          })}
                    </TableBody>
                  </>
                )}
              </Table>
            </TableContainer>

            {platformOverviewData !== null && platformOverviewData?.length > 5 && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.viewMoreButtonGrid}
              >
                {dataCount === 5 ? (
                  <Button onClick={loadMoreData}>
                    <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
                      {t("View_More")}
                    </Typography>
                  </Button>
                ) : (
                  <Button onClick={loadLessData}>
                    <Typography sx={{ fontSize: "14px", fontWeight: 700 }}>
                      {t("View_Less")}
                    </Typography>
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PlatformOverview;
